<template>
  <div :class="cssclass">
    <Grid
      ref="grid"
      :additional-api-arguments="apiArgs"
      :enable-pagination="true"
      :enable-history="!actionRunning"
      :default-sort-by="defaultSortBy"
      :default-sort-desc="defaultSortDesc"
      :pagination-show-first-last-page="paginationShowFirstLastPage"
      :pagination-show-totals="paginationShowTotals"
      :enabled-columns="columnSwitchOptions.activeColumns"
      :headers="headers"
      :single-select="false"
      :show-select="true"
      :item-class="itemClass"
      data-store-action="domain/fetchData"
      form-name="domain-index-form"
      item-key="domainidn"
    >
      <!-- filter slot with additional filter -->
      <template v-slot:filters>
        <v-col class="col-12 col-sm-6 col-md-2 filter-search">
          <v-text-field
            :label="$t('domain_index.search')"
            :value="apiArgs.restarting.search.value"
            dense
            filled
            hide-details
            placeholder=""
            @change="apiArgs.restarting.search.value = $event"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-2 filter-folder">
          <FolderSelector
            :add-item-to-reset-filter="true"
            :item-value="apiArgs.restarting.folder.value"
            @update_folder="apiArgs.restarting.folder.value = $event"
          />
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-2 filter-column-presets">
          <v-select
            :items="columnSwitchOptions.available"
            :label="$t('domain_index.columns')"
            :menu-props="{ contentClass: 'filter-column-presets-menu', bottom: true, offsetY: true }"
            :value="columnSwitchOptions.activeColumns"
            dense
            filled
            hide-details
            item-text="text"
            item-value="columns"
            id="filter-column-presets-select"
            @change="columnSwitchOptions.activeColumns = $event"
            prepend-inner-icon="mdi-view-column-outline"
          ></v-select>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-6 more-filters text-right">
          <MoreFiltersButton class="mt-2" @openMoreFiltersDialog="onOpenMoreFiltersDialog" :number-of-selected-filters="numberOfSelectedFilters" />
        </v-col>
      </template>
      <template v-slot:toolbarSelect v-if="getGridHasResults()">
        <div class="toolbar-select d-inline-flex">
          <v-simple-checkbox v-model="$refs.grid.isBulkEnabled" class="px-2 bulk-checkbox" color="primary" :ripple="false" />
          <span class="pt-2">{{ $t('actions.all_pages') }}</span>
          <div v-if="$refs.grid.isBulkEnabled || $refs.grid.selected.length" class="d-inline-flex">
            <span class="toolbar-select-count px-6 pt-2" v-if="$refs.grid.isBulkEnabled">
              {{ $tc('domain_index.domain_selected', $refs.grid.data.total) }}
            </span>
            <span class="toolbar-select-count px-6 pt-2" v-else>{{ $tc('domain_index.domain_selected', $refs.grid.selected.length) }}</span>
          </div>
        </div>
        <div v-if="$refs.grid.isBulkEnabled || $refs.grid.selected.length" class="toolbar-select-actions d-inline-flex">
          <ActionButton :description="$t('actions.edit_domains')" icon="mdi-pencil-outline" @click="openEditPage($refs.grid.selected)" />
          <ActionButton
            :description="$t('actions.move_folder')"
            icon="mdi-folder-move-outline"
            @click="openActionDialog($refs.grid.selected, 'showMoveToFolderDialog')"
          />
          <ActionButton
            :description="$t('actions.delete_domains')"
            icon="mdi-delete-outline"
            @click="openActionDialog($refs.grid.selected, 'showDeleteDomainDialog')"
          />
        </div>
      </template>
      <template v-slot:[`item.domainidn`]="{ item }">
        <router-link :to="'/stats/domain/date/' + item.domain" class="domain-stats-link">
          <span class="domain column-domainidn">{{ item.domainidn }}</span>
        </router-link>
      </template>
      <template v-slot:[`item.folder`]="{ item }">
        <span class="folder column-folder">{{ item.folder === 1 ? 'Default' : getFolderName(item.folder) }}</span>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ $f.price(item.price, 'USD', true) }}
      </template>
      <template v-slot:[`item.forsale_linkstyle`]="{ item }">
        <Crop max-width="100px" :value="getBannerNameById(item.forsale_linkstyle)" />
      </template>
      <template v-slot:[`item.forsale_template`]="{ item }">
        <Crop max-width="100px" :value="getSaleTemplateNameById(item.forsale_template)" />
      </template>
      <template v-slot:[`item.custom_text`]="{ item }">
        <Crop max-width="80px" :value="getSaleTextById(item.custom_text)" />
      </template>
      <template v-slot:[`item.sellurl_name`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlNameById(item.sellurl)" />
      </template>
      <template v-slot:[`item.sellurl_url`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlLinkById(item.sellurl)" />
      </template>
      <template v-slot:[`item.forsale_type`]="{ item }">
        <span class="forsale_type">{{ $t('domain_index.forsale_type_' + item.forsale_type) }}</span>
      </template>
      <template v-slot:[`item.min_offer`]="{ item }">
        {{ $f.price(item.min_offer, item.currency) }}
      </template>
      <template v-slot:[`item.captcha`]="{ item }">
        <span class="captcha">{{ $f.boolean(item.captcha) }}</span>
      </template>
      <template v-slot:[`item.datefirst`]="{ item }">
        <span class="datefirst" :title="item.datefirst">{{ $f.shortDate(item.datefirst) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="column-actions">
          <ActionMenu>
            <template v-slot:actionMenuItems>
              <ActionMenuItem
                :text="$t('actions.preview_page')"
                icon="mdi-feature-search-outline"
                :href="getDomainURL(item.domainidn)"
                target="_blank"
                class="preview-page"
              />
              <ActionMenuItem :text="$t('actions.edit_domain')" icon="mdi-pencil-outline" @click="openEditPage([item])" class="domain-edit" />
              <ActionMenuItem
                :text="$t('actions.move_folder')"
                icon="mdi-folder-move-outline"
                @click="openActionDialog([item], 'showMoveToFolderDialog')"
                class="domain-move"
              />
              <ActionMenuItem
                :text="$t('actions.delete_domain')"
                icon="mdi-delete-outline"
                @click="openActionDialog([item], 'showDeleteDomainDialog')"
                class="domain-delete"
              />
            </template>
          </ActionMenu>
        </div>
      </template>
    </Grid>

    <ActionDialog
      mode="move"
      :show-dialog="showMoveToFolderDialog"
      :title="getDialogTitle($t('misc.move'))"
      :item-value="moveToFolderId"
      @dialog:close="closeActionDialog('showMoveToFolderDialog')"
      @dialog:confirmed="moveToFolder"
      :waiting-for-end-of-action="actionRunning"
      @update_folder="moveToFolderId = $event"
    />

    <ActionDialog
      mode="delete"
      :show-dialog="showDeleteDomainDialog"
      :title="getDialogTitle($t('misc.delete'))"
      :text="$t('domain_index.domain_delete_info')"
      @dialog:close="closeActionDialog('showDeleteDomainDialog')"
      @dialog:confirmed="deleteDomain"
      :waiting-for-end-of-action="actionRunning"
    />

    <MoreFiltersDialog
      :show-dialog="showMoreFiltersDialog"
      @closeDialog="onCloseMoreFiltersDialog"
      :config="moreFiltersDialogConfig"
      @applyFilters="onApplyFilters"
      @removeFilter="onRemoveFilter"
      @clearFilters="onClearFilters"
    />
  </div>
</template>

<script>
import FolderSelector from '@/components/folder/FolderSelector'
import Grid from '@/components/grid/Grid'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import Crop from '@/components/others/Crop'
import ReferenceResolverMixin from '@/components/grid/ReferenceResolverMixin'
import DomainListMixin from '@/components/domain/DomainListMixin'
import MoreFiltersMixin from '@/components/others/mixins/MoreFiltersMixin'
import ActionButton from '@/components/app/ActionButton'
import ActionDialog from '@/components/others/ActionDialog'
import ActionMenuItem from '@/components/app/ActionMenuItem'
import ActionMenu from '@/components/app/ActionMenu'

export default {
  mixins: [GridBaseMixin, ReferenceResolverMixin, DomainListMixin, MoreFiltersMixin],
  components: {
    ActionMenu,
    ActionMenuItem,
    ActionDialog,
    ActionButton,
    FolderSelector,
    Grid,
    Crop
  },
  data() {
    let parkingColumnSet = {
      text: 'Parking',
      columns: ['domainidn', 'folder', 'datefirst', 'actions']
    }
    let salesColumnSet = {
      text: 'Sale',
      columns: [
        'domainidn',
        'folder',
        'forsale_type',
        'forsale_linkstyle',
        'forsale_template',
        'custom_text',
        'sellurl_name',
        'sellurl_url',
        'min_offer',
        'captcha',
        'datefirst',
        'actions'
      ]
    }
    return {
      moreFiltersDialogConfig: [
        {
          label: this.$t('domain_index.sale_type'),
          apiArgsField: 'forsale_type',
          source: 'saleTypes',
          type: 'select',
          itemValue: 'value',
          itemText: 'text'
        },
        {
          label: this.$t('domain_index.sale_banner'),
          apiArgsField: 'forsale_linkstyle',
          source: 'saleBanners',
          type: 'select',
          itemValue: 'id',
          itemText: 'name'
        },
        {
          label: this.$t('domain_index.sellurl'),
          apiArgsField: 'sellurl',
          source: 'saleUrls',
          type: 'select',
          itemValue: 'id',
          itemText: 'name'
        },
        {
          label: this.$t('domain_index.custom_text'),
          apiArgsField: 'custom_text',
          source: 'saleTexts',
          type: 'select',
          itemValue: 'id',
          itemText: 'text'
        },
        {
          label: 'Divider'
        },
        {
          label: this.$t('domain_index.tld'),
          apiArgsField: 'tld',
          type: 'search'
        },
        {
          label: this.$t('domain_index.is_deleted'),
          apiArgsField: 'is_deleted',
          type: 'boolean',
          trueValue: 1,
          falseValue: 0,
          defaultValue: 0
        },
        {
          label: this.$t('domain_index.imprint'),
          apiArgsField: 'useimprint',
          type: 'boolean',
          trueValue: 1,
          falseValue: 0,
          defaultValue: 0
        },
        {
          label: this.$t('domain_index.direct_advertiser'),
          apiArgsField: 'directadv',
          type: 'boolean',
          trueValue: 1,
          falseValue: 0,
          defaultValue: 0
        },
        {
          label: this.$t('domain_index.show_domain_name'),
          apiArgsField: 'show_domainname',
          type: 'boolean',
          trueValue: 'yes',
          falseValue: 'no',
          defaultValue: 'no'
        }
      ],
      showMoveToFolderDialog: false,
      showDeleteDomainDialog: false,
      paginationShowFirstLastPage: true,
      paginationShowTotals: true,
      userFolder: [],
      moveToFolderId: null,
      data: {
        total: 0
      },
      defaultSortBy: ['datefirst'],
      defaultSortDesc: [true],
      cssclass: '', // set to 'compact' for smaller rows
      headers: [
        { text: '', value: 'actions', sortable: false },
        { text: this.$t('domain_index.domain'), value: 'domainidn', sortable: false },
        { text: this.$t('domain_index.folder'), value: 'folder', sortable: false },
        { text: this.$t('domain_index.sale_type'), value: 'forsale_type', sortable: false },
        { text: this.$t('domain_index.sale_template'), value: 'forsale_template', sortable: false },
        { text: this.$t('domain_index.sale_banner'), value: 'forsale_linkstyle', sortable: false },
        { text: this.$t('domain_index.sellurl_name'), value: 'sellurl_name', sortable: false },
        { text: this.$t('domain_index.sellurl_url'), value: 'sellurl_url', sortable: false },
        { text: this.$t('domain_index.custom_text'), value: 'custom_text', sortable: false },
        { text: this.$t('domain_index.min_offer'), value: 'min_offer', sortable: false, align: 'right' },
        { text: this.$t('domain_index.captcha'), value: 'captcha', sortable: false },
        { text: this.$t('domain_index.added'), value: 'datefirst', sortable: true }
      ],
      columnSwitchOptions: {
        activeColumns: salesColumnSet.columns,
        available: [parkingColumnSet, salesColumnSet]
      },
      apiArgs: {
        restarting: {
          folder: this.buildArgument('folder', folder => parseInt(folder) >= 0),
          search: {
            value: this.queryParamOrDefaultValue('search', null),
            check: search => search !== '',
            argumentProvider: (apiArguments, search) => {
              if (search !== '') {
                apiArguments['domain'] = search
              }
            }
          },
          forsale_type: this.buildArgument('forsale_type'),
          forsale_linkstyle: this.buildArgument('forsale_linkstyle', forsale_linkstyle => forsale_linkstyle >= 0),
          sellurl: this.buildArgument('sellurl', sellurl => sellurl >= 0),
          custom_text: this.buildArgument('custom_text', custom_text => custom_text >= 0),
          tld: this.buildArgument('tld'),
          is_deleted: this.buildArgument('is_deleted', is_deleted => is_deleted != null),
          useimprint: this.buildArgument('useimprint', useimprint => useimprint === 0 || useimprint === 1),
          show_domainname: this.buildArgument('show_domainname', show_domainname => show_domainname === 'yes' || show_domainname === 'no'),
          directadv: this.buildArgument('directadv', directadv => directadv === 0 || directadv === 1)
        },
        persistent: {}
      },
      domaindata: {},
      filterPanel: false
    }
  },
  mounted() {
    this.loadSaleData()
    this.$on('api_data_retrieved', data => {
      let hasTotal = data != null && data.total != null
      this.paginationShowFirstLastPage = hasTotal
      this.paginationShowTotals = hasTotal
    })
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

tr.inactive-domain
  background: $input-background
</style>
