<template>
  <v-dialog v-model="isShown" content-class="more-filters-dialog" persistent transition="slide-x-reverse-transition">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col class="text-right">
            <PlainButton @click="closeDialog" class="close-dialog-btn"><v-icon>mdi-close</v-icon></PlainButton>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row v-if="selectedFilters.length > 0">
            <v-col>
              <div class="active-filter-headline">{{ $t('more_filters.active_filters') }}</div>

              <div class="active-filter-chips-container">
                <div
                  class="active-filter-chip"
                  v-for="selectedFilter in selectedFilters"
                  :key="selectedFilter.name"
                  @click="removeFilter(selectedFilter)"
                >
                  <span
                    ><strong>{{ selectedFilter.label }}:</strong> {{ selectedFilter.valueText }}</span
                  ><v-icon size="18px">mdi-close</v-icon>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <strong>{{ $t('more_filters.select_a_filter') }}</strong>
              <v-select
                id="more-filters-dialog-filter-select"
                :menu-props="{ contentClass: 'more-filters-dialog-filter-menu', bottom: true, offsetY: true }"
                hide-details
                :items="availableFilters"
                label="Filter"
                filled
                dense
                item-text="label"
                v-model="selectedFilter"
              />
            </v-col>
          </v-row>

          <v-row v-if="loadedSelectedFilterConfig !== null && loadedSelectedFilterConfig.type === 'select'">
            <v-col>
              <v-select
                :items="this[loadedSelectedFilterConfig.source]"
                :label="loadedSelectedFilterConfig.label"
                filled
                :menu-props="{ contentClass: 'select-filter-menu', bottom: true, offsetY: true }"
                hide-details
                dense
                :item-text="loadedSelectedFilterConfig.itemText"
                :item-value="loadedSelectedFilterConfig.itemValue"
                v-model="selectedFilterValue"
                return-object
              />
            </v-col>
          </v-row>

          <v-row v-if="loadedSelectedFilterConfig !== null && loadedSelectedFilterConfig.type === 'search'">
            <v-col>
              <v-text-field
                :label="$t('more_filters.search') + ' ' + loadedSelectedFilterConfig.label"
                dense
                filled
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="search-input"
                v-model="selectedFilterValue"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="loadedSelectedFilterConfig !== null && loadedSelectedFilterConfig.type === 'boolean'">
            <v-col>
              <FormSwitch
                :label="loadedSelectedFilterConfig.label"
                v-model="selectedFilterValue"
                :true-value="loadedSelectedFilterConfig.trueValue"
                :false-value="loadedSelectedFilterConfig.falseValue"
              />
            </v-col>
          </v-row>

          <v-row v-if="loadedSelectedFilterConfig !== null && loadedSelectedFilterConfig.type === 'stats'">
            <v-col cols="12">
              <v-select
                :label="$t('more_filters.condition')"
                filled
                dense
                :menu-props="{ contentClass: 'select-condition-menu', bottom: true, offsetY: true }"
                v-model="selectedCondition"
                :items="availableConditions"
                item-text="text"
                item-value="value"
                hide-details
                return-object
              />
            </v-col>
          </v-row>

          <v-row
            v-if="
              loadedSelectedFilterConfig !== null &&
                loadedSelectedFilterConfig.type === 'stats' &&
                selectedCondition !== null &&
                (selectedCondition.value === 'is_greater_or_equal_than' ||
                  selectedCondition.value === 'is_lesser_or_equal_than' ||
                  selectedCondition.value === 'equals')
            "
          >
            <v-col cols="12">
              <v-text-field :label="$t('more_filters.value')" dense filled hide-details v-model="selectedFilterValue" />
            </v-col>
          </v-row>

          <v-row
            v-if="
              loadedSelectedFilterConfig !== null &&
                loadedSelectedFilterConfig.type === 'stats' &&
                selectedCondition !== null &&
                selectedCondition.value === 'is_between'
            "
          >
            <v-col cols="5">
              <v-text-field :label="$t('more_filters.value')" dense filled hide-details v-model="selectedMinFilterValue" />
            </v-col>
            <v-col cols="2">
              <v-icon class="mt-3" large>mdi-minus</v-icon>
            </v-col>
            <v-col cols="5">
              <v-text-field :label="$t('more_filters.value')" dense filled hide-de tails v-model="selectedMaxFilterValue" />
            </v-col>
          </v-row>

          <v-row v-if="selectedFilterValue !== null || (selectedMinFilterValue !== null && selectedMaxFilterValue !== null)">
            <v-col class="text-right">
              <SecondaryButton @click="addFilter" class="add-filter-btn">{{ $t('more_filters.add_filter') }}</SecondaryButton>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col class="dialog-action-buttons">
            <PlainButton @click="clearFilters" class="float-left clear-filters-btn">
              <v-icon left>mdi-rotate-left</v-icon>{{ $t('more_filters.clear_filters') }}
            </PlainButton>
            <PrimaryButton @click="applyFilters" class="float-right apply-filters-btn">{{ $t('more_filters.apply_filters') }}</PrimaryButton>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from '@/components/app/PrimaryButton'
import PlainButton from '@/components/app/PlainButton'
import SecondaryButton from '@/components/app/SecondaryButton'
import FormSwitch from '@/components/app/FormSwitch'
import RouteQueryHelper from '@/util/RouteQueryHelper'

export default {
  name: 'MoreFiltersDialog',
  components: {
    PrimaryButton,
    PlainButton,
    SecondaryButton,
    FormSwitch
  },
  props: {
    showDialog: {
      Boolean,
      default: false
    },
    config: {
      type: Array,
      default: () => []
    }
  },
  async mounted() {
    await this.loadSaleData()
    this.buildFiltersArray()
    let queryParams = RouteQueryHelper.fromQuery(this.$route.query)
      .removeMany(['page', 'per_page'])
      .getAll()
    queryParams = this.restoreMinMaxFiltersFromQueryParams(queryParams)
    this.restoreFiltersFromQueryParams(queryParams)
  },
  data() {
    return {
      isShown: this.$props.showDialog,
      availableFilters: [],
      selectedFilters: [],
      availableConditions: [
        {
          text: this.$t('more_filters.is_greater_or_equal_than'),
          value: 'is_greater_or_equal_than',
          prefix: 'min'
        },
        {
          text: this.$t('more_filters.is_lesser_or_equal_than'),
          value: 'is_lesser_or_equal_than',
          prefix: 'max'
        },
        {
          text: this.$t('more_filters.is_between'),
          value: 'is_between',
          prefix: 'min_max'
        },
        {
          text: this.$t('more_filters.equals'),
          value: 'equals',
          prefix: 'min_max'
        }
      ],
      saleTypes: [
        { text: 'All', value: null },
        { text: this.$t('domain_index.forsale_type_notforsale'), value: 'notforsale' },
        { text: this.$t('domain_index.forsale_type_externallink'), value: 'externallink' },
        { text: this.$t('domain_index.forsale_type_contact'), value: 'contact' }
      ],
      saleBanners: [],
      saleUrls: [],
      saleTexts: [],
      selectedFilter: null,
      selectedFilterValue: null,
      selectedCondition: null,
      loadedSelectedFilterConfig: null,
      selectedMinFilterValue: null, // Needed for is between condition to work with two values
      selectedMaxFilterValue: null
    }
  },
  watch: {
    showDialog() {
      this.isShown = this.$props.showDialog
    },
    selectedFilter() {
      // this happens when we reset the state. also it would cause an error for loadedSelectedFilterConfig being undefined
      if (this.selectedFilter === null) {
        return
      }

      let config = this.$props.config

      this.loadedSelectedFilterConfig = config.find(item => {
        return item.label === this.selectedFilter
      })

      // On Boolean toggles add a default value
      if (this.loadedSelectedFilterConfig.type === 'boolean') {
        this.selectedFilterValue = this.loadedSelectedFilterConfig.defaultValue
      }
    }
  },
  methods: {
    closeDialog() {
      this.isShown = false
      this.$emit('closeDialog')
    },
    async loadSaleData() {
      await this.$store.dispatch('sale_banner/fetchData', { per_page: 1000 }).then(response => {
        this.saleBanners = response.data.data
      })

      await this.$store.dispatch('sale_url/fetchData', { per_page: 1000 }).then(response => {
        this.saleUrls = response.data.data
      })

      await this.$store.dispatch('sale_text/fetchData', { per_page: 1000 }).then(response => {
        this.saleTexts = response.data.data
      })
    },
    buildFiltersArray() {
      this.$props.config.forEach(item => {
        let availableFilterItem = {}
        availableFilterItem.disabled = false

        if (item.label === 'Divider') {
          availableFilterItem.divider = true
        } else {
          availableFilterItem.label = item.label
        }

        this.availableFilters.push(availableFilterItem)
      })
    },
    addFilter() {
      if (this.loadedSelectedFilterConfig.type === 'search') {
        this.addSearchFilter()
      }

      if (this.loadedSelectedFilterConfig.type === 'select') {
        this.addSelectFilter()
      }

      if (this.loadedSelectedFilterConfig.type === 'boolean') {
        this.addBooleanFilter()
      }

      if (this.loadedSelectedFilterConfig.type === 'stats') {
        this.addStatsFilter()
      }

      this.resetState()
    },
    addSearchFilter() {
      let filter = {}
      filter.label = this.loadedSelectedFilterConfig.label
      filter.name = this.loadedSelectedFilterConfig.apiArgsField
      filter.value = this.selectedFilterValue
      filter.valueText = this.selectedFilterValue

      this.selectedFilters.push(filter)
      this.toggleDisabledState(filter.label)
    },
    addSelectFilter() {
      let filter = {}
      filter.label = this.loadedSelectedFilterConfig.label
      filter.name = this.loadedSelectedFilterConfig.apiArgsField

      filter.value = this.selectedFilterValue[this.loadedSelectedFilterConfig.itemValue]
      let valueText = this.selectedFilterValue[this.loadedSelectedFilterConfig.itemText]

      if (typeof valueText === 'object') {
        valueText = valueText.join()
      }

      filter.valueText = valueText

      this.selectedFilters.push(filter)
      this.toggleDisabledState(filter.label)
    },
    addBooleanFilter() {
      let filter = {}
      filter.label = this.loadedSelectedFilterConfig.label
      filter.name = this.loadedSelectedFilterConfig.apiArgsField
      filter.value = this.selectedFilterValue

      if (!isNaN(this.selectedFilterValue)) {
        this.selectedFilterValue = parseInt(this.selectedFilterValue)
      }

      if (this.selectedFilterValue === this.loadedSelectedFilterConfig.trueValue) {
        filter.valueText = 'Yes'
      } else {
        filter.valueText = 'No'
      }

      this.selectedFilters.push(filter)
      this.toggleDisabledState(filter.label)
    },
    addStatsFilter() {
      let filter = {}
      filter.label = this.loadedSelectedFilterConfig.label

      switch (this.selectedCondition.value) {
        case 'is_greater_or_equal_than':
          filter.name = 'min_' + this.loadedSelectedFilterConfig.apiArgsField
          filter.value = this.selectedFilterValue
          filter.valueText = this.$t('more_filters.is_greater_or_equal_than') + ' ' + this.selectedFilterValue
          filter.condition = 'is_greater_or_equal_than'
          break
        case 'is_lesser_or_equal_than':
          filter.name = 'max_' + this.loadedSelectedFilterConfig.apiArgsField
          filter.value = this.selectedFilterValue
          filter.valueText = this.$t('more_filters.is_lesser_or_equal_than') + ' ' + this.selectedFilterValue
          filter.condition = 'is_lesser_or_equal_than'
          break
        case 'is_between':
          filter.name = this.loadedSelectedFilterConfig.apiArgsField
          filter.minValue = this.selectedMinFilterValue
          filter.maxValue = this.selectedMaxFilterValue
          filter.valueText = this.$t('more_filters.is_between') + ' ' + this.selectedMinFilterValue + ' - ' + this.selectedMaxFilterValue
          filter.condition = 'between'
          break
        case 'equals':
          filter.name = this.loadedSelectedFilterConfig.apiArgsField
          filter.minValue = this.selectedFilterValue
          filter.maxValue = this.selectedFilterValue
          filter.valueText = this.$t('more_filters.equals') + ' ' + this.selectedFilterValue
          filter.condition = 'equals'
          break
      }

      this.selectedFilters.push(filter)
      this.toggleDisabledState(filter.label)
    },
    toggleDisabledState(filter) {
      this.availableFilters.forEach((item, index) => {
        if (item.label === filter) {
          this.availableFilters[index].disabled = !this.availableFilters[index].disabled
        }
      })
    },
    resetState() {
      this.selectedFilterValue = null
      this.selectedFilter = null
      this.loadedSelectedFilterConfig = null
      this.selectedCondition = null
      this.selectedMinFilterValue = null
      this.selectedMaxFilterValue = null
    },
    removeFilter(filter) {
      this.selectedFilters = this.selectedFilters.filter(selectedFilter => {
        return selectedFilter.name !== filter.name
      })

      this.toggleDisabledState(filter.label)
      this.$emit('removeFilter', filter)
    },
    applyFilters() {
      this.$emit('applyFilters', {
        filters: this.selectedFilters
      })

      this.closeDialog()
    },
    clearFilters() {
      this.$emit('clearFilters', {
        filters: this.selectedFilters
      })

      this.availableFilters.forEach((filter, index) => {
        this.availableFilters[index].disabled = false
      })

      this.selectedFilters = []
    },
    restoreMinMaxFiltersFromQueryParams(queryParams) {
      // filter all min filters
      let minFilters = Object.entries(queryParams).filter(parameter => {
        return parameter[0].startsWith('min')
      })

      // filter all max filters
      let maxFilters = Object.entries(queryParams).filter(parameter => {
        return parameter[0].startsWith('max')
      })

      // check if min filter have opposite max filter
      minFilters.forEach(filter => {
        const filterName = filter[0]
        const filterValue = filter[1]
        const filterNameWithoutPrefix = filterName.split('min_')[1]
        const oppositeFilterName = 'max_' + filterNameWithoutPrefix

        const oppositeFilter = maxFilters.find(maxFilter => {
          return maxFilter[0] === oppositeFilterName
        })

        this.$props.config.forEach(item => {
          if (item.apiArgsField === filterNameWithoutPrefix) {
            this.loadedSelectedFilterConfig = item
          }
        })

        if (typeof oppositeFilter !== 'undefined') {
          // we have a min/max filter here (is between or equals)
          if (filterValue !== oppositeFilter[1]) {
            // if the values differ we have an is between condition
            this.selectedFilter = this.loadedSelectedFilterConfig.label
            this.selectedMinFilterValue = filterValue
            this.selectedMaxFilterValue = oppositeFilter[1]
            this.selectedCondition = {
              prefix: 'min_max',
              text: this.$t('more_filters.is_between'),
              value: 'is_between'
            }
          } else {
            // otherwise it is an equal condition
            this.selectedFilter = this.loadedSelectedFilterConfig.label
            this.selectedFilterValue = filterValue
            this.selectedCondition = {
              prefix: 'min_max',
              text: this.$t('more_filters.equals'),
              value: 'equals'
            }
          }
        } else {
          // we have no opposite max filter so we can assume an is_greater_or_equal_than filter
          this.selectedFilter = this.loadedSelectedFilterConfig.label
          this.selectedFilterValue = filterValue
          this.selectedCondition = {
            prefix: 'min',
            text: this.$t('more_filters.is_greater_or_equal_than'),
            value: 'is_greater_or_equal_than'
          }
        }

        // if we have an opposite max filter it is already used by its min_filter opponent
        // therefore we can reduce the maxFilters array so that only max filters without an according min filter
        // are in this list
        maxFilters = maxFilters.filter(maxFilter => {
          return typeof oppositeFilter === 'undefined' || maxFilter[0] !== oppositeFilter[0]
        })

        this.addFilter()
        this.resetState()
      })

      maxFilters.forEach(filter => {
        // because all possible connections have already been solved, we can safely assume an is_lesser_or_equal_than filter here
        const filterName = filter[0]
        const filterValue = filter[1]
        const filterNameWithoutPrefix = filterName.split('max_')[1]

        this.$props.config.forEach(item => {
          if (item.apiArgsField === filterNameWithoutPrefix) {
            this.loadedSelectedFilterConfig = item
          }
        })

        this.selectedFilter = this.loadedSelectedFilterConfig.label
        this.selectedFilterValue = filterValue
        this.selectedCondition = {
          prefix: 'max',
          text: this.$t('more_filters.is_lesser_or_equal_than'),
          value: 'is_lesser_or_equal_than'
        }

        this.addFilter()
        this.resetState()
      })

      return queryParams
    },
    restoreFiltersFromQueryParams(queryParams) {
      // we separate the restore logic into two different methods because the minMax logic is too different from the
      // other restore logic.
      for (const [key, value] of Object.entries(queryParams)) {
        this.$props.config.forEach(item => {
          if (item.apiArgsField === key) {
            this.loadedSelectedFilterConfig = item

            if (item.type === 'select') {
              this.selectedFilterValue = this[item.source].find(sourceItem => {
                return sourceItem[item.itemValue] === value
              })
            } else {
              this.selectedFilterValue = value
            }

            this.addFilter()
            this.resetState()
          }
        })
      }

      this.applyFilters()
    },
    setActiveFilterWithDefaultValue() {
      this.$props.config.forEach(item => {
        if (item.isActiveValue) {
          this.selectedFilter = item.label
          this.loadedSelectedFilterConfig = item
          this.selectedFilterValue = item.defaultValue
          this.addFilter()
          this.resetState()
        }
      })

      this.applyFilters()
    }
  }
}
</script>

<style lang="sass">
@import '@/sass/variables'

#app
  .more-filters-dialog
    position: absolute
    right: 0
    height: 100%
    width: 400px
    margin: 0
    border-radius: 10px 0 0 10px
    max-height: 100%

    .v-card
      height: 100%

    .add-filter-btn
      color: $primary

    .active-filter-chip
      color: $primary
      border: 1px solid $primary
      border-radius: 5px
      cursor: pointer
      display: inline-block
      margin-bottom: 10px
      width: 100%
      max-width: 100%
      position: relative
      background: $primary-light

      &:hover
        filter: brightness(90%)

      span
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        display: inline-block
        padding: 8px 5px 0 10px
        max-width: 240px

      i
        position: absolute
        right: 10px
        top: 8px
        width: 10px
        color: $primary

    .active-filter-chips-container

      @media screen and (max-width: 768px)
        max-height: 100px

      @media screen and (min-width: 768px)
        max-height: 300px

      overflow-y: auto

    .search-input
      .v-input__slot
        border-radius: 34px


    .active-filter-headline
      font-weight: bold

    .v-card__actions
      position: absolute
      bottom: 20px
      width: 100%

      .dialog-action-buttons

        .v-btn
          margin-left: 15px

          &.clear-filters-btn
            color: $primary
            font-weight: bold

          &.apply-filters-btn
            background-color: $primary
            color: #fff
</style>
