<template>
  <div class="folder-list">
    <v-autocomplete
      id="filter-folder-select"
      hide-details
      @change="updateFolder"
      :items="folders"
      item-text="name"
      item-value="id"
      :label="$t('domain_index.folder')"
      v-model="selectedFolder"
      :key="selectedFolder"
      :menu-props="{
        zIndex: 1000,
        contentClass: 'folder-list-menu'
      }"
      filled
      dense
      append-icon="$dropdown"
      prepend-inner-icon="mdi-folder-outline"
    ></v-autocomplete>
  </div>
</template>

<script>
import i18n from '../../i18n'
import Logger from '@/util/Logger'

export default {
  props: {
    addItemToResetFilter: {
      Boolean,
      default: false
    },
    itemValue: {
      default: null
    },
    appendIcon: {
      type: String,
      default: '$dropdown'
    }
  },
  data() {
    return {
      folders: this.addItemToResetFilter ? [{ id: null, name: i18n.t('misc.all') }] : [],
      selectedFolder: this.$props.itemValue
    }
  },
  methods: {
    updateFolder() {
      this.$emit('update_folder', this.selectedFolder)
    }
  },
  watch: {
    '$props.itemValue': {
      handler: function(id) {
        this.selectedFolder = id
      },
      immediate: true
    }
  },
  mounted() {
    this.$store
      .dispatch('folder/fetchData', { per_page: 1000 })
      .then(() => {
        let systemAndUserFolders = this.$store.getters['folder/getSystemAndUserFolders'] ?? []
        this.addItemToResetFilter
          ? (this.folders = [...[{ id: null, name: i18n.t('misc.all') }], ...systemAndUserFolders])
          : (this.folders = systemAndUserFolders)
      })
      .catch(error => {
        Logger.log("Error loading user's folder", { error })
      })
  }
}
</script>
