<template>
  <v-banner v-bind="{ ...$props, ...$attrs }" v-on="$listeners" v-model="dataValue" outlined rounded shaped :color="bannerColor">
    <div class="d-flex">
      <v-icon :color="color">{{ icon }}</v-icon>
      <span class="banner-message ml-4 ma-2" :style="textStyle">{{ text }}</span>
    </div>
  </v-banner>
</template>

<script>
import ComponentWrapperMixin from './ComponentWrapperMixin.js'

export default {
  name: 'InfoBanner',
  mixins: [ComponentWrapperMixin],
  props: {
    type: {
      default: 'info',
      type: String
    },
    text: {
      default: 'Banner text'
    }
  },
  computed: {
    icon: function() {
      let iconMap = { info: 'mdi-information', error: 'mdi-close-thick', warning: 'mdi-alert', success: 'mdi-check' }
      return iconMap[this.type] ?? 'mdi-question'
    },
    color: function() {
      return this.$vuetify.theme.themes.light[this.type]
    },
    bannerColor: function() {
      return this.color + '22'
    },
    textStyle: function() {
      return 'color: ' + this.$vuetify.theme.themes.light[this.type]
    }
  }
}
</script>
