<template>
  <Dialog
    :visible="showActionDialog"
    dialogMaxWidth="500"
    @dialog:close="$emit('dialog:close')"
    @dialog:confirmed="$emit('dialog:confirmed')"
    :waiting-for-end-of-action="$props.waitingForEndOfAction"
  >
    <template v-slot:title>
      <div>{{ $props.title }}</div>
    </template>
    <template v-slot:content>
      <div v-if="$props.mode === 'move'">
        <FolderSelector :item-value="moveToFolderId" @update_folder="onUpdateFolder(parseInt($event))" />
      </div>
      <div v-if="$props.mode === 'delete'">
        <Banner :text="$props.text" type="info" />
      </div>
    </template>
    <template v-slot:buttonText>
      {{ $t('misc.' + $props.mode) }}
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/others/Dialog'
import FolderSelector from '@/components/folder/FolderSelector'
import Banner from '@/components/others/Banner'

export default {
  components: {
    Dialog,
    FolderSelector,
    Banner
  },
  props: {
    showDialog: {
      Boolean,
      default: false
    },
    mode: {
      type: String
    },
    title: {
      type: String
    },
    text: {
      default: 'ActionDialog text'
    },
    itemValue: {
      default: null
    },
    waitingForEndOfAction: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      showActionDialog: this.$props.showDialog,
      moveToFolderId: null
    }
  },
  watch: {
    showDialog() {
      this.showActionDialog = this.$props.showDialog
    },
    '$props.itemValue': {
      handler: function(val) {
        this.moveToFolderId = val
      },
      immediate: true
    }
  },
  methods: {
    onUpdateFolder(folderId) {
      this.moveToFolderId = folderId
      this.$emit('update_folder', folderId)
    }
  }
}
</script>
