<template>
  <div>
    <PlainButton @click="$emit('openMoreFiltersDialog')" class="more-filters-button">
      <v-icon left>
        mdi-filter
      </v-icon>
      {{ $t('more_filters.more_filters') }}
    </PlainButton>
    <v-badge :content="numberOfSelectedFilters" class="number-of-selected-filters" v-if="numberOfSelectedFilters > 0" />
  </div>
</template>

<script>
import PlainButton from '@/components/app/PlainButton'
export default {
  name: 'MoreFiltersButton',
  components: {
    PlainButton
  },
  props: {
    numberOfSelectedFilters: {
      Number,
      default: 0
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'
#app
  .more-filters
    .v-btn
      color: $primary

  .number-of-selected-filters
    margin: 0 20px 5px -5px
</style>
