<template>
  <div id="domain-index">
    <v-row no-gutters>
      <v-col class="col-md-10">
        <Headline />
      </v-col>
      <v-col class="col-md-2 text-right">
        <PrimaryButton url="/domains_add">{{ $t('domain_index.add_domains') }}</PrimaryButton>
      </v-col>
    </v-row>
    <DomainIndex />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import PrimaryButton from '@/components/app/PrimaryButton.vue'
import DomainIndex from '@/components/domain/DomainIndex'

export default {
  components: {
    Headline,
    PrimaryButton,
    DomainIndex
  }
}
</script>
