import MoreFiltersButton from '@/components/app/MoreFiltersButton'
import MoreFiltersDialog from '@/components/others/MoreFiltersDialog'

export default {
  components: {
    MoreFiltersButton,
    MoreFiltersDialog
  },
  data() {
    return {
      numberOfSelectedFilters: 0,
      showMoreFiltersDialog: false
    }
  },
  methods: {
    onApplyFilters(event) {
      let apiArgs = { restarting: {} }

      // Filter
      if (event.filters.length > 0) {
        event.filters.forEach(filter => {
          if (filter.condition === 'between' || filter.condition === 'equals') {
            let minArg = this.apiArgs.restarting['min_' + filter.name]
            minArg.value = filter.minValue
            apiArgs.restarting['min_' + filter.name] = minArg

            let maxArg = this.apiArgs.restarting['max_' + filter.name]
            maxArg.value = filter.maxValue
            apiArgs.restarting['max_' + filter.name] = maxArg
          } else {
            let arg = this.apiArgs.restarting[filter.name]
            arg.value = filter.value
            apiArgs.restarting[filter.name] = arg
          }
        })

        this.numberOfSelectedFilters = event.filters.length
        this.apiArgs.restarting = { ...this.apiArgs.restarting, ...apiArgs.restarting }
      }
    },
    onRemoveFilter(filter) {
      let apiArgs = { restarting: {} }
      apiArgs = this.clearFilter(apiArgs, filter)

      this.apiArgs.restarting = { ...this.apiArgs.restarting, ...apiArgs.restarting }
      this.numberOfSelectedFilters -= 1
    },
    clearFilter(apiArgs, filter) {
      if (filter.condition === 'between' || filter.condition === 'equals') {
        let minArg = this.apiArgs.restarting['min_' + filter.name]
        minArg.value = null
        apiArgs.restarting['min_' + filter.name] = minArg

        let maxArg = this.apiArgs.restarting['max_' + filter.name]
        maxArg.value = null
        apiArgs.restarting['max_' + filter.name] = maxArg
      } else {
        let arg = this.apiArgs.restarting[filter.name]
        arg.value = null
        apiArgs.restarting[filter.name] = arg
      }

      return apiArgs
    },
    onClearFilters(event) {
      this.$refs.grid.$data.apiArgs.persistent.sort.value = null
      this.numberOfSelectedFilters = 0

      let apiArgs = { restarting: {} }

      if (event.filters.length > 0) {
        event.filters.forEach(filter => {
          apiArgs = this.clearFilter(apiArgs, filter)
        })

        this.apiArgs.restarting = { ...this.apiArgs.restarting, ...apiArgs.restarting }
      }
    },
    onOpenMoreFiltersDialog() {
      this.showMoreFiltersDialog = true
    },
    onCloseMoreFiltersDialog() {
      this.showMoreFiltersDialog = false
    }
  }
}
