<template>
  <v-btn rounded :to="url" :href="href" class="secondary-button" @click="$emit('click')" :disabled="disabled">
    <slot name="default"></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    url: {
      required: false
    },
    href: {
      required: false
    },
    text: {
      required: false
    },
    disabled: {
      required: false,
      default: false
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .secondary-button
  background-color: $sheet-background
  color: $primary
  border: 1px solid $primary
  box-shadow: none
  text-decoration: none
  text-transform: none
  &:hover
    box-shadow: none
  &:active
    box-shadow: none
  .v-icon
    color: $primary

  &.v-btn--disabled
    cursor: not-allowed
    background-color: $sheet-background
    border: 1px solid $sheet-background
    box-shadow: none
    text-decoration: none
    text-transform: none
</style>
