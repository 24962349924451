<template>
  <div class="action-button px-1">
    <v-btn icon :small="this.small" :regular="this.regular" :href="href" @click="$emit('click')">
      <ToolTip :description="description" :icon="icon" />
    </v-btn>
  </div>
</template>

<script>
import ToolTip from '@/components/others/ToolTip'

export default {
  name: 'ActionButton',
  components: { ToolTip },
  props: {
    href: {
      String,
      required: false
    },
    description: {
      String,
      required: true
    },
    icon: {
      String,
      required: true
    },
    buttonSize: {
      String,
      default: 'regular',
      validator: propValue => {
        return ['small', 'regular'].includes(propValue)
      }
    }
  },
  computed: {
    small() {
      return this.buttonSize === 'small'
    },
    regular() {
      return this.buttonSize === 'regular'
    }
  }
}
</script>
